import React, { useState, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './index.css';
import Home from './pages/Home/Home';
import Portfolyo from './pages/Portfolyo/Portfolyo';
import { MdSunny } from "react-icons/md";
import { BsFillMoonFill } from "react-icons/bs";

function App() {
  const [theme, setTheme] = useState('dark');

  useEffect(() => {
    document.title = "Merve Aydin"; 
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
  };

  return (
    <Router>
      <div className="min-h-screen" style={{ background: theme === 'dark' ? '#111111' : '#f0f0f0' }}>
        <header className="flex justify-end pr-8 pt-8">
          <button onClick={toggleTheme} className="text-4xl">
            {theme === 'dark' ? <MdSunny color="gray"/> : <BsFillMoonFill color="gray" />}
          </button>
        </header>
        <main className="p-10">
          <Routes>
            <Route path="/" element={<Home theme={theme} />} />
            <Route path="/portfolio" element={<Portfolyo />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
