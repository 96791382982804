import React from 'react';
import { FaEnvelope, FaGithub, FaLinkedin, FaMedium } from 'react-icons/fa';
import { IoMdRocket } from 'react-icons/io';
import './Home.css'; 

const Home = ({ theme }) => {
  const textColor = theme === 'dark' ? 'text-white' : 'text-gray-900';
  const titleColor = theme === 'dark' ? 'text-gray-400' : 'text-black';

  return (
    <div className="home-container">
      <div className="profile-image-container">
        <img src="/appimage/ss.jpg" alt="Profile" className="profile-image" />
      </div>
      <div className="introduction-container">
        <div className="introduction-content">
          <div className={`text-center ${textColor}`}>
            <h1 className={`title ${titleColor}`}>REACT NATIVE • DESIGNER • READER</h1>
            <h1 className={`name ${textColor}`}>I'M MERVE AYDIN.</h1>
            <h2 className={`subtitle ${textColor}`}>FRONT-END / MOBILE<br/>DEVELOPER</h2>
            <p className={`description ${textColor}`}><br />
              Merhaba, ben Merve. Front-End ve Mobil geliştiricisiyim. 
              Şu anda ekibimle birlikte yürüttüğümüz bir projede, aktif olarak görev almaktayım. Daha fazla bilgi için, CV'mi inceleyebilirsiniz.
            </p> 
          </div>
        </div>
      </div>
      <div className="icons-container">
        <div className="icon-wrapper">
          <a href="/portfolio" className="icon-link" aria-label="Portfolio">
            <IoMdRocket className="icon" />
          </a>
          <div className="tooltip">Portfolio</div>
        </div>
        <div className="icon-wrapper">
          <a href="mailto:merveaydincode@gmail.com" className="icon-link" aria-label="Email">
            <FaEnvelope className="icon" />
          </a>
          <div className="tooltip">Email</div>
        </div>
        <div className="icon-wrapper">
          <a href="https://github.com/merveaydincode" target="_blank" rel="noopener noreferrer" className="icon-link" aria-label="GitHub">
            <FaGithub className="icon" />
          </a>
          <div className="tooltip">GitHub</div>
        </div>
        <div className="icon-wrapper">
          <a href="https://www.linkedin.com/in/merveaydin-/" target="_blank" rel="noopener noreferrer" className="icon-link" aria-label="LinkedIn">
            <FaLinkedin className="icon" />
          </a>
          <div className="tooltip">LinkedIn</div>
        </div>
        <div className="icon-wrapper">
          <a href="https://medium.com/@merveaydincode" target="_blank" rel="noopener noreferrer" className="icon-link" aria-label="Medium">
            <FaMedium className="icon" />
          </a>
          <div className="tooltip">Medium</div>
        </div>
      </div>
    </div>
  );
};

export default Home;
